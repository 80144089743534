<template>
  <div>
    <div class="p-3 bg-gray-200">
      <w-loading v-if="busy"></w-loading>
      <div v-else>
        <div class="grid gap-4 grid-cols-1 my-2 w-full">
          <div
            class="bg-white dark:bg-gray-900 border border-gray-400 dark:border-gray-800 rounded-lg p-4 max-w-72 w-full"
            style="cursor: auto"
          >
            <a
              aria-label="Unsplash Downloads"
              target="_blank"
              rel="noopener noreferrer"
              href="https://stackdiary.com/"
            >
              <div
                class="flex items-center text-gray-900 dark:text-gray-100 text-2xl font-medium"
                style="cursor: auto"
              >
                Editorial Orsai
              </div>
            </a>
            <!-- Dos Div separados por una linea vertical -->
            <div class="grid grid-cols-2 divide-x mt-4">
              <div class="pr-6">
                <!-- Ventas de hoy -->
                <p
                  class="mt-1 text-sm md:text-normal spacing-sm text-black dark:text-white"
                  style="cursor: auto"
                >
                  Ventas de hoy
                </p>
                <p
                  class="mt-1 text-xl md:text-4xl font-semibold spacing-sm text-black dark:text-white"
                  style="cursor: auto"
                >
                  {{ ventasHoy.ars.totalAmount | formatearDinero }}
                </p>
                <p class="text-xs md:text-sm text-gray-600 dark:text-gray-400" style="cursor: auto">
                  {{ ventasHoy.ars.totalQuantity | formatearCantidad }}
                  {{ ventasHoy.ars.totalQuantity > 1 ? "ventas" : "venta" }}
                </p>
              </div>
              <div class="pl-6">
                <!-- Ventas del mes -->
                <p
                  class="mt-1 text-sm md:text-normal spacing-sm text-black dark:text-white"
                  style="cursor: auto"
                >
                  Ventas del mes
                </p>
                <p
                  class="mt-1 text-xl md:text-4xl font-semibold spacing-sm text-black dark:text-white"
                  style="cursor: auto"
                >
                  {{ ventasMes.ars.totalAmount | formatearDinero }}
                </p>
                <p class="text-xs md:text-sm text-gray-600 dark:text-gray-400" style="cursor: auto">
                  {{ ventasMes.ars.totalQuantity | formatearCantidad }}
                  {{ ventasMes.ars.totalQuantity > 1 ? "ventas" : "venta" }}
                </p>
              </div>
            </div>
            <div class="flex justify-between items-center mt-4 text-xs md:text-sm">
              <div>{{ ventasMes.ars.totalAmount | formatearDinero }} a liquidar</div>
              <div>
                <router-link
                  class="text-blue-500 cursor-pointer flex items-center"
                  to="/movimientos"
                >
                  Ir a movimientos
                  <!-- Caret -->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2.5"
                    stroke="currentColor"
                    class="ml-1 w-3 h-3"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M8.25 4.5l7.5 7.5-7.5 7.5"
                    />
                  </svg>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div v-if="false" class="grid gap-4 grid-cols-1 my-2 w-full">
          <div
            class="bg-white dark:bg-gray-900 border border-gray-400 dark:border-gray-800 rounded-lg p-4 max-w-72 w-full"
            style="cursor: auto"
          >
            <a
              aria-label="Unsplash Downloads"
              target="_blank"
              rel="noopener noreferrer"
              href="https://stackdiary.com/"
            >
              <div
                class="flex items-center text-gray-900 dark:text-gray-100 text-2xl font-medium"
                style="cursor: auto"
              >
                Audiovisuales Orsai
              </div>
            </a>
            <!-- Dos Div separados por una linea vertical -->
            <!-- <div class="grid grid-cols-1 divide-x mt-4">
              <div
                :key="i"
                v-for="(project, i) in projects"
                class="flex justify-between items-start mb-2 pb-1 border-b"
              >
                <div class="">
                  <div class="text-sm font-semibold">«{{ project.name }}»</div>
                  <div class="text-xs text-gray-700">
                    {{ project.detalle }}
                  </div>
                </div>
                <div class="w-1/2 text-right">
                  <span
                    class="px-2.5 py-0.5 rounded text-xs bg-blue-500 text-white"
                  >
                    {{ project.bonos }} BONO{{ project.bonos > 1 ? "S" : "" }}
                  </span>
                </div>
              </div>
            </div> -->
            <!-- <div class="flex justify-end items-center mt-4 text-xs md:text-sm">
              <div>
                <router-link
                  class="text-blue-500 cursor-pointer flex items-center"
                  to="/movimientos"
                >
                  Ir a movimientos
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2.5"
                    stroke="currentColor"
                    class="ml-1 w-3 h-3"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M8.25 4.5l7.5 7.5-7.5 7.5"
                    />
                  </svg>
                </router-link>
              </div>
            </div> -->
          </div>
        </div>
        <div v-if="false" class="grid gap-4 grid-cols-1 my-2 w-full mb-24">
          <div
            class="bg-white dark:bg-gray-900 border border-gray-400 dark:border-gray-800 rounded-lg p-4 max-w-72 w-full"
            style="cursor: auto"
          >
            <a
              aria-label="Unsplash Downloads"
              target="_blank"
              rel="noopener noreferrer"
              href="https://stackdiary.com/"
            >
              <div
                class="flex items-center text-gray-900 dark:text-gray-100 text-2xl font-medium"
                style="cursor: auto"
              >
                Eventos Orsai
              </div>
            </a>
            <!-- Dos Div separados por una linea vertical -->
            <div class="grid grid-cols-1 divide-x mt-4">
              <div
                :key="i"
                v-for="(evento, i) in eventos"
                class="flex space-x-4 justify-between items-start mb-2 pb-1 border-b"
              >
                <div class="w-2/5">
                  <div class="text-sm font-semibold elipsis-title">
                    {{ evento.titulo }}
                  </div>
                  <div class="text-xs text-gray-700">
                    {{ evento.locacion }}
                  </div>
                </div>
                <div class="text-xs text-gray-700 w-1/6">
                  <div class="">
                    {{ evento.date | parseDate }}
                  </div>
                </div>
                <!-- Badge bonos -->
                <div class="w-2/5 text-right flex items-center space-x-2 justify-end">
                  <evento-cupos :show-range="false" class="text-xs text-gray-900" :event="evento" />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2.5"
                    stroke="currentColor"
                    class="ml-1 w-3 h-3 text-blue-500"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M8.25 4.5l7.5 7.5-7.5 7.5"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div class="flex justify-end items-center mt-4 text-xs md:text-sm">
              <div>
                <router-link
                  class="text-blue-500 cursor-pointer flex items-center"
                  to="/movimientos"
                >
                  Ir a movimientos
                  <!-- Caret -->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2.5"
                    stroke="currentColor"
                    class="ml-1 w-3 h-3"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M8.25 4.5l7.5 7.5-7.5 7.5"
                    />
                  </svg>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventoCupos from "@/components/nuevo/EventoCupos.vue";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "reporteAutores",
  layout: "autores",
  components: {
    EventoCupos,
  },
  data() {
    return {
      busy: false,
      loading: false,
      page: 0,
      skip: 0,
      limit: 20,
      loaded: false,
      searchTerm: null,
      ventasMes: null,
      ventasHoy: null,
      ultimaSemana: null,
      eventos: [],
    };
  },
  async created() {
    this.busy = true;
    const ventasMes = await this.crearEstadistica({
      tipo: "mes-actual",
      autor: "novamas",
    });
    this.ventasHoy = await this.crearEstadistica({
      tipo: "dia-actual",
      autor: "novamas",
    });
    this.ultimaSemana = await this.crearEstadistica({
      tipo: "chart-semana",
      autor: "novamas",
    });

    let tmpEventos = await this.findEvents({
      query: {
        $limit: 5,
        $sort: {
          createdAt: -1,
        },
      },
    });

    this.eventos = tmpEventos.data;

    this.ventasMes = ventasMes;
    this.busy = false;
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("orders", { findResultsInStore: "find" }),
    projects() {
      return [
        {
          name: "5 Guiones",
          detalle: "Guiones audiovisuales",
          bonos: Math.floor(Math.random() * 10),
        },
        {
          name: "Peretti Project",
          detalle: "Largometraje",
          bonos: Math.floor(Math.random() * 10),
        },
        {
          name: "Canelones",
          detalle: "Miniserie",
          bonos: Math.floor(Math.random() * 10),
        },
        {
          name: "Sola en el paraíso",
          detalle: "Documental",
          bonos: Math.floor(Math.random() * 10),
        },
        {
          name: "La Uruguaya",
          detalle: "Largometraje",
          bonos: Math.floor(Math.random() * 10),
        },
      ];
    },

    labels() {
      if (!this.ultimaSemana) return [];
      return this.ultimaSemana.data.map((item) => item.date);
    },
    data() {
      if (!this.ultimaSemana) return [];
      return this.ultimaSemana.data.map((item) => item.totalQuantity);
    },
  },
  methods: {
    ...mapActions("orders", { findResults: "find" }),
    ...mapActions("eventos", { findEvents: "find" }),
    ...mapActions("estadisticas-autores", { crearEstadistica: "create" }),
  },
  filters: {
    formatearDinero(value) {
      return new Intl.NumberFormat("es-AR", {
        style: "currency",
        currency: "ARS",
        maximumFractionDigits: 0,
      }).format(value);
    },
    formatearCantidad(value) {
      return new Intl.NumberFormat("es-AR", {
        maximumFractionDigits: 0,
      }).format(value);
    },
    parseDate(value) {
      //  09 SEP with moment
      let tmp = moment(value).format("DD MMM");
      // Remove last dot
      return tmp.slice(0, -1).toUpperCase();
    },
  },
};
</script>

<style lang="scss" scoped>
.elipsis-title {
  // Only one line, then elipsis
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
